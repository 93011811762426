/* anything with the !important flag in here has it because of an imposed class that comes in on inline DatePicker component that is set by Blockparty */

.calendar-datepicker {
  .react-datepicker {
    border: none;
    box-shadow: none !important;
    width: 100%;

    .react-datepicker__month-container {
      width: 100%;
    }

    .react-datepicker__header {
      border: none;
      padding: 0;

      .react-datepicker__day-names {
        border-bottom: 1px solid #e0e0e0;
      }
    }

    .react-datepicker__day-names,
    .react-datepicker__week {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2px;
    }

    .react-datepicker__navigation {
      width: 20px;
      height: 20px;
      top: 0px;
    }

    .react-datepicker__navigation--previous,
    .react-datepicker__navigation--next {
      border: none;
    }

    .react-datepicker__navigation--previous {
      left: auto;
      right: 24px;
      background: url(../../../static/img/ArrowChevronLeft.svg) center no-repeat;
      background-size: cover;
    }

    .react-datepicker__navigation--next {
      right: 0;
      background: url(../../../static/img/ArrowChevronRight.svg) center no-repeat;
      background-size: cover;
    }

    .react-datepicker__month {
      margin: 0;
      padding: 0;
    }

    .react-datepicker__current-month {
      font-size: 14px;
      font-weight: 600;
      height: 22px;
      line-height: 22px;
      text-align: left;
      margin-bottom: 8px;
      padding: 0;
    }

    .react-datepicker__day--outside-month {
      color: #757575;
    }

    .react-datepicker__day-names,
    .react-datepicker__day {
      font-size: 10px;
    }

    .react-datepicker__day-name,
    .react-datepicker__day {
      width: 24px;
      margin: 0;
    }

    .react-datepicker__day-name {
      height: 18px;
      line-height: 18px;
    }

    .react-datepicker__day {
      height: 24px;
      line-height: 24px;
    }

    .react-datepicker__day--keyboard-selected {
      background-color: transparent;
      border-radius: unset;
      outline: unset;
      color: #000;

      &:focus {
        box-shadow: unset;
      }
    }

    .react-datepicker__day--selected {
      background: #e3f6fc;
      color: #222222;

      &.react-datepicker__day--today {
        background: #087ea8;
        color: #fff;
      }
    }

    .react-datepicker__day--today {
      background-color: #087ea8;
      border-radius: 50%;
      color: #fff;
    }
  }
}
