.opsware-upload-dropzone {
  .dropzone {
    &:focus {
      outline: none;
    }
    .opsware-upload-dropdown-wrapper {
      border-color: #c2c2c2;
      &:hover {
        border-color: #8f8f8f;
      }
    }
  }
}
